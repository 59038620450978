.apps-grid__container {
  flex: 1 1 auto;
}

.apps-grid__row--even,
.apps-grid__row--odd {
  border-bottom: 1px solid #e0e0e0;
}

.apps-grid__row--odd {
  background-color: #fafafa;
}

.apps-grid__cell {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 .5em;
  word-break: break-all;
  align-items: center;
  text-align: center;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  overflow: hidden;
}

.apps-grid__cell--header {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
}

.apps-grid__empty {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}
