.raw-data {
  display: flex;
  height: 100%;
}

.raw-data__container {
  display: inline-flex;
  flex-direction: column;
  margin: 35px auto auto;
  width: 40%;
  align-items: flex-end;
}

.raw-data__text-area {
  width: 100%;
}

.raw-data__text-area + .raw-data__submit {
  margin-top: 10px;
}
