.app-info {
  background: #f0f0f0;
  border-radius: 3px;
  padding: 10px;
}

.app-info__table {
  border-collapse: collapse;
}

.app-info__property:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

.app-info__property__key, .app-info__property__value {
  padding: 10px 2px;
}

.app-info__property__key {
  font-weight: bold;
}

.app-info__property__value {
  word-break: break-all;
}
