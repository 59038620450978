.similar-apps {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.similar-apps__data {
  overflow-y: scroll;
  padding: 10px 20px;
}

.similar-apps__data .app-info + .app-info {
  margin-top: 10px;
}
