.app-form {
  box-sizing: border-box;
}

.app-form__form {
  display: flex;
  flex-direction: column;
}

.app-form__buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.app-form__key-pages-header {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-form__key-pages {
  padding-left: 20px;
  margin-bottom: 10px;
}

.app-form__key-page {
  display: flex;
  align-items: baseline;
}

.app-form__key-page__text-field {
  flex-grow: 1;
}
