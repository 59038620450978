.amend-new-apps {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.amend-new-apps__container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.amend-new-apps__similar-apps {
  flex: 1 0 50%;
  min-width: 0;
  overflow: hidden;
}

.amend-new-apps__app-form-container {
  border-left: 1px solid #e0e0e0;
  overflow-y: scroll;
  flex: 1 0 50%;
  min-width: 0;
  padding: 0 20px;
  box-sizing: border-box;
}
